// Polyfills
import 'es6-object-assign/auto';
import 'core-js/fn/array/includes';
import 'core-js/fn/promise/finally';
import 'intl'; // For Safari 9

import React from 'react';
import ReactDOM from 'react-dom';
import Swal from 'sweetalert2';

import AppStateHOC from '../lib/app-state-hoc.jsx';
import BrowserModalComponent from '../components/browser-modal/browser-modal.jsx';
import supportedBrowser from '../lib/supported-browser';

import styles from './index.css';

const appTarget = document.createElement('div');
appTarget.className = styles.app;
document.body.appendChild(appTarget);

if (supportedBrowser()) {
  // require needed here to avoid importing unsupported browser-crashing code
  // at the top level
  require('./render-gui.jsx').default(appTarget);
  // -------------------------------更新----------------------------
  runScratch()


} else {
  alert("抱歉，浏览器不支持。我们建议您使用Chrome内核的浏览器！");
  BrowserModalComponent.setAppElement(appTarget);
  const WrappedBrowserModalComponent = AppStateHOC(BrowserModalComponent, true /* localesOnly */);
  const handleBack = () => { };
  // eslint-disable-next-line react/jsx-no-bind
  ReactDOM.render(<WrappedBrowserModalComponent onBack={handleBack} />, appTarget);
}

// ------------------------------------更新--------------------------------------------------------

function runScratch() {
  // <script src="https://www.thdpy.cn/scratchPython/update.js"></script>

  var script = document.createElement('script');
  script.src = 'https://www.thdpy.cn/scratchPython/update.js?'+(Date.now()/8640).toFixed(0);
  document.head.appendChild(script);
  setTimeout(function () {
    console.log("++++++++++++++++" + JSON.stringify(window.hpy.scratchPython.update.version))
    if(!window.hpy.scratchPython.update.version) return
    let version="3.6.6.240228"
    if (isUpgrade(version, window.hpy.scratchPython.update.version) && window.hpy.scratchPython.update.reqireUpdate) {
      // if (confirm("请更新最新版本？")) {
      //     // 用户点击了确定按钮，执行操作
      //     console.log("用户点击了确定按钮");
      //     // 执行其他操作
      //     window.location.href = "https://www.thdpy.cn/scratchPython/update.js";
      //     // window.open("https://www.thdpy.cn/scratchPython/update.js", "_blank");

      //  } else {
      //     // 用户点击了取消按钮，不执行操作
      //     console.log("用户点击了取消按钮");
      //     // 执行其他操作
      //  }
      Swal.fire({
        title: '请更新最新版本',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        allowOutsideClick: true, // 允许点击对话框外部关闭
        backdrop: false, // 不显示背景遮罩
      }).then((result) => {
        if (result.isConfirmed) {
          // 点击了确定按钮
          //   window.location.href = "https://www.thdpy.cn/scratchPython/update.js";
          window.open(window.hpy.scratchPython.update.href, "_blank");
        } else {
          // 点击了取消按钮
          console.log("用户取消");
        }
      });;

    }
  }, 2000)

};
//比较版本号大小
function isUpgrade(currentVersion, newVersion) {
  const currentVersionNumbers = currentVersion.split('.').map(Number);
  const newVersionNumbers = newVersion.split('.').map(Number);

  for (let i = 0; i < currentVersionNumbers.length; i++) {
    if (currentVersionNumbers[i] < newVersionNumbers[i]) {
      return true;
    } else if (currentVersionNumbers[i] > newVersionNumbers[i]) {
      return false;
    }
  }

  return false;
}

